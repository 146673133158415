import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { axiosInstanceLive } from '../../../instance/Axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spin } from 'antd';
import { Space } from 'antd';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { HomeOutlined } from '@ant-design/icons';
import { Typography } from '@mui/material';
import { Modal, Row, Col } from 'antd';
import { Button as AntButton } from 'antd';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { useSelector } from 'react-redux';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { setDisablerFalse, setDisablerTrue } from 'redux/SidebarDisablerSlice';
import { useDispatch } from 'react-redux';

// import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
const commonStyles = {
    m: 0
};

export default function DenseTable() {
    // const customerError = () =>
    //     toast.error(customerErrMessage, {
    //         position: 'top-right',
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: 'light'
    //     });

    // const notify = () =>
    //     toast.error(isFailedMessage, {
    //         position: 'top-right',
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: 'light'
    //     });

    // const customerNotify = () =>
    //     toast.success('Customer created successfully', {
    //         position: 'top-right',
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: 'light'
    //     });
    const dispatch = useDispatch();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    //STATE MANAGEMENT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //State to store mode
    const [paymentMode, setPaymentMode] = useState('IMPS');
    //Selected benef details state

    const [selectedBenefData, setSelectedBenefData] = useState('');
    // console.log('selectedBenefData', selectedBenefData);
    //State to store response of amount-values Api
    const [amountResponse, setAmountResponse] = useState('');
    const [amountError, setAmountError] = useState('');
    //Dropdown helpers
    const [customer, setcustomer] = useState('');
    // console.log('customer in state now : ', customer);
    const [beneficiary, setBeneficiary] = useState('');
    // console.log('beneficiary in state :', beneficiary);

    //Arrays to map
    const [customerMap, setCustomerMap] = useState([]);
    // console.log('customerMap', customerMap);
    const [beneficiaryMap, setBeneficiaryMap] = useState([]);
    // console.log('beneficiaryMap : ', beneficiaryMap);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    // console.log('!!!', selectedCustomer);
    const [choosedBenef, setChoosedBenef] = useState(null);

    //State to store amount
    const [tamount, setTamount] = useState();
    //Is loading state for button change
    const [isLoading, setIsLoading] = useState(false);
    const [maxLimitLoading, setMaxLimitLoading] = useState(false);
    const [senderno, setSenderno] = useState('');
    // console.log('senderno in state :', senderno);
    const [maxLimit, setMaxLimit] = useState(0);
    //State to check the transfer is success
    const isSuccess = true;
    const isFirstRender = true;
    const [isTrue, setIsTrue] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isFailedMessage, setIsFailedMessage] = useState('');
    // const [customerErrMessage, setCustomerErrMessage] = useState('');
    const [isTransfer, setIsTransfer] = useState(true);
    const [merchantEmpID, setMerchantEmpID] = useState('');
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    // console.log('isSuccess', isSuccess);
    const location = useLocation();
    const navigate = useNavigate();
    // const senderno = location.state.data.customermob;
    const CreateCustomerRes = location?.state?.Data;
    // console.log('location data', location.state);
    const transfer = 'fino-transfer';
    const id = location?.state?.data?.id;
    // console.log('id location : ', id);
    // const index = location?.state?.index;
    const indexBenef = location?.state?.indexBenef;
    // console.log('location state : ', location.state);
    const uid = localStorage.getItem('id');
    const email = localStorage.getItem('email_id');
    const data = location?.state?.item || location?.state?.data;
    const type = 'fino';
    const benAcc = selectedBenefData?.beneAccountNo;
    const benIfsc = selectedBenefData?.beneIfsc;
    const beneMobileNo = selectedBenefData?.beneMobile;
    const amount = amountResponse?.amount;
    const servicecharge = 0;
    const beneName = selectedBenefData?.beneName;
    const Sdist_commission = amountResponse?.sdist_commission;
    const Dist_commission = amountResponse?.dist_commission;
    const Agent_commission = amountResponse?.agent_commission;
    const Agst = amountResponse?.gst;
    const Atds = amountResponse?.tds;
    const Tservice_charge = amountResponse?.service_charge;
    const TnewserviceCharge = amountResponse?.newserviceCharge;
    const txn_agentDebitAmount = amountResponse?.agentDebitAmount;
    const agent = localStorage.getItem('id');
    const isEmployee = localStorage.getItem('isEmployee');
    // const senderno = localStorage.getItem('sendernumber')
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    //States and handlers for modal component
    const [open, setOpen] = useState(false);
    // console.log('open modal: ', open);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const balanceW1 = useSelector((state) => state?.wallet?.balance?.roundedNumber);
    const handleTransferClick = () => {
        setIsFailedMessage('');
        setIsFailed(false);
        if (tamount >= 100) {
            // showModal();
            fetchValues();
        } else {
            setAmountError('The minimum transfer amount is 100');
            globalNotifyError('The minimum transfer amount is 100');
        }
    };
    const showModal = () => {
        // console.log('is failed? :', isFailed);
        if (customer && beneficiary && tamount) {
            setOpen(true);
        } else {
            // console.log('show modal else !');
            setIsFailedMessage('Make sure you have filled all the fields');
            // console.log('Is failed should be false :', isFailed);

            setIsFailed(true);
            // console.log('is failed? :', isFailed);

            setIsLoading(false);
        }
    };
    const handleOk = () => {
        // console.log('ok');
        setConfirmLoading(true);
        transferMoney();
    };
    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpen(false);
        dispatch(setDisablerFalse());
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };

    //STATE MANAGEMENT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    //Functions to API calls>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const customerDetails = async () => {
        // console.log('uid, email, senderno, type', uid, email, senderno, type);
        const token = localStorage.getItem('token');
        try {
            const response = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/moneytransfer_dmt',
                { uid, email, senderno, type },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const customerApi = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/indexverifysender_dmt_api?transfer=fino-transfer',
                { uid },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // console.log('resss : ', response);
            const customerList = customerApi?.data?.data?.customersList;
            const benefData = response?.data?.data?.benfDetails;
            // console.log('customer api response : ', customerList);
            // console.log('money transfer dmt api response :', response);
            // console.log('benefData', benefData);
            setCustomerMap(customerList);
            setBeneficiaryMap(benefData);
            // console.log('customerApi : ', customerList);
            // console.log('response of moneytransfer api : ', response.data.meta.benfDetails);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Api to fetch values when amount entered
    const fetchValues = async () => {
        // console.log('fetch values called');
        setIsTrue(true);
        try {
            const token = localStorage.getItem('token');
            const values = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/GetChargesDMTtransfer',
                { uid, transfer, paymentMode, tamount },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // console.log('values', values);

            setIsTrue(false);
            setAmountResponse(values?.data);
            showModal();
        } catch (error) {
            console.log(error.message);
            setIsTrue(false);
            handleCatchError(error);
        }
    };

    //Api to fetch data of single beneficiary on change of dropdown
    const fetchBenefDetails = async () => {
        const token = localStorage.getItem('token');
        const type = transfer;
        const id = beneficiary;
        try {
            const singleBenefData = await axiosInstanceLive.post(
                `/api/V2/moneytransfer/loadTransferApi`,
                { email, type, id, senderno },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // console.log('singleBenefData', singleBenefData);
            const detailsOfBenef = singleBenefData?.data?.data;
            setSelectedBenefData(detailsOfBenef);
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Api to tranfer on click of button>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const transferMoney = async () => {
        // console.log('clicked');
        setIsLoading(true);
        setIsFailed(false);
        setIsFailedMessage('');
        const token = localStorage.getItem('token');
        try {
            if (customer && beneficiary && amount) {
                // console.log('sender no in api :', senderno);
                setIsFailed(false);
                const transferResponse = await axiosInstanceLive.post(
                    '/api/V2/moneytransfer/fundTransfer_dmt_Api',
                    {
                        transfer,
                        uid,
                        email,
                        senderno,
                        benAcc,
                        benIfsc,
                        beneMobileNo,
                        paymentMode,
                        amount,
                        servicecharge,
                        beneName,
                        Sdist_commission,
                        Dist_commission,
                        Agent_commission,
                        Agst,
                        Atds,
                        Tservice_charge,
                        TnewserviceCharge,
                        txn_agentDebitAmount
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Header-Merchant-Employee': merchantEmpID,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                const detailsOfTransfer = transferResponse;
                // console.log('detailsOfTransfer', detailsOfTransfer);
                const apistatus = detailsOfTransfer?.data?.status;
                const orderId = detailsOfTransfer?.data?.data?.orderId;
                // console.log(orderId);
                // console.log('apistatus', apistatus);
                setOpen(false);
                dispatch(setDisablerFalse());
                setConfirmLoading(false);
                if (apistatus) {
                    setIsTransfer(true);
                    navigate('/dmt/dmtreciept', { state: { data, orderId, isTransfer } });
                    // console.log(orderId);
                    // window.location.reload();
                } else {
                    setIsFailed(true);
                    setIsFailedMessage(detailsOfTransfer?.data?.message);
                }
                setIsLoading(false);
            } else {
                // console.log('in else');
                setIsFailedMessage('Make sure you have filled all the fields');
                setIsFailed(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //UPDATE BENEFICIARY AS PER THE SELECTED CUSTOMER
    // const updateBeneficiary = async () => {
    //     // console.log('customerId', customerId);
    //     const token = localStorage.getItem('token');
    //     try {
    //         const response = await axiosInstanceLive.post(
    //             '/api/V2/moneytransfer/moneytransfer_dmt',
    //             { uid, email, senderno, type },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                     'Content-Type': 'application/json'
    //                 }
    //             }
    //         );
    //         // console.log('response on change ', response);
    //         const benefData = response?.data?.data?.benfDetails;
    //         setBeneficiaryMap(benefData);
    //         // console.log('beneficiaryMap', beneficiaryMap);
    //     } catch (error) {
    //         console.log(error);
    //         handleCatchError(error);
    //     }
    // };

    const getTransactionLimit = async () => {
        // console.log('agent senderno type :', agent, senderno, type);
        try {
            setMaxLimitLoading(true);
            const token = localStorage.getItem('token');
            const response = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/CheckVerifynosender_dmt_api',
                { agent, senderno, type },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            // console.log('Limit response : ', response?.data);

            //This if condition is tempor
            // if (response?.data?.status) {
            // console.log('status trueee', response.status);
            const maximumLimit = response?.data?.data?.limit;
            if (maximumLimit >= 0) {
                setMaxLimitLoading(false);
                setMaxLimit(maximumLimit);
            } else {
                setMaxLimitLoading(false);
                setMaxLimit(0);
            }
            // } else {
            //     console.log('status false');
            //     setCustomerErrMessage(response.message);
            //     customerError();
            // }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //FUNCTION TO FETCH SENDER NUMBER ON CUSTOMER CHANGE
    // const getSendernoOnChange = (customerId) => {
    //     try {
    //         const allCustomers = location?.state?.filteredData;
    //         // console.log('customerId', customerId);
    //         // console.log('allCustomers', allCustomers);
    //         const filteredCustomer = allCustomers?.filter((data) => data?.id === customerId);
    //         // console.log('filteredCustomer new:', filteredCustomer);
    //         const number = filteredCustomer[0]?.customermob;
    //         // console.log('changed number', number);
    //         setSenderno(number);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    //EVENT HANDLERS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const handleModeChange = (event) => {
        event.preventDefault();
        setPaymentMode(event.target.value);
    };
    const handleBeneficiaryChange = (event, newValue) => {
        setBeneficiary(newValue?.id);
        setChoosedBenef(newValue);
    };
    // const handleCustomerChange = (event, newValue) => {
    //     event.preventDefault();
    //     setIsFirstRender(false);
    //     setChoosedBenef(null);
    //     // console.log('newValue full :', newValue);
    //     const customerId = newValue?.id;
    //     // console.log('customerId on change :', customerId);
    //     setSelectedCustomer(newValue);
    //     getSendernoOnChange(customerId);
    //     // console.log('event.target.value', event.target.value);
    //     setcustomer(newValue?.id);
    //     // updateBeneficiary(event.target.value);
    // };
    const validateAmount = () => {
        const amountValue = tamount;
        const amountValueFloat = parseFloat(amountValue);
        const balanceW1Float = parseFloat(balanceW1);
        if (amountValueFloat <= balanceW1Float && amountValue <= maxLimit) {
            setAmountError('');
        } else {
            if (amountValueFloat > balanceW1Float) {
                setAmountError('Entered amount exceeds the wallet balance!');
            } else if (amountValue > maxLimit) {
                setAmountError('The entered amount exceeds the maximum limit');
            }
        }
    };
    const handleAmountChange = (e) => {
        setAmountError('');
        const amountValue = e?.target?.value;
        // const amountValueFloat = parseFloat(amountValue);
        // const balanceW1Float = parseFloat(balanceW1);

        // if (amountValueFloat >= 0) {
        // if (amountValueFloat <= balanceW1Float && amountValue <= maxLimit) {
        const numberRegex = /^[0-9.]*$/;
        if (numberRegex.test(amountValue)) {
            setTamount(amountValue);
        } else {
            globalNotifyError('Enter valid numbers only');
        }
        // } else {
        //     if (amountValueFloat > balanceW1Float) {
        //         setAmountError('Entered amount exceeds the wallet balance!');
        //     } else if (amountValue > maxLimit) {
        //         setAmountError('The entered amount exceeds the maximum limit');
        //     }
        // }
        // if (amountValue <= maxLimit) {
        //     setTamount(amountValue);
        // } else {
        //     setAmountError('The entered amount exceeds the maximum limit');
        // }
        // } else {
        //     setAmountError('Please enter a valid amount.');
        // }
        // if (amountValueFloat > 0) {
        // } else {
        //     setAmountError('Please enter a valid amount.');
        // }
    };
    useEffect(() => {
        if (open) {
            dispatch(setDisablerTrue());
        }
        // eslint-disable-next-line
    }, [open]);
    useEffect(() => {
        // setBeneficiaryMap();
        // setSenderno();
        if (senderno) {
            customerDetails();
            // updateBeneficiary();
            getTransactionLimit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderno]);

    useEffect(() => {
        const checkIsMerchantOrBc = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            // const globalMerchantPermission = useSelector((state) => state);
            // console.log('globalMerchantPermission', globalMerchantPermission);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus) {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        checkIsMerchantOrBc();
        if (CreateCustomerRes) {
            globalNotifySuccess('Customer created successfully');
        }
        const allCustomers = location?.state?.filteredData;
        const filteredCustomer = allCustomers?.filter((data) => data?.id === id);
        if (filteredCustomer?.length > 0) {
            const number = filteredCustomer[0]?.customermob;
            setSenderno(number);
        }

        if (location?.state?.senderno) {
            setSenderno(location?.state?.senderno);
        }
        setcustomer(id);
        const beneficiaryFromNavigate = location?.state?.row ? location?.state?.row?.id : '';
        setBeneficiary(beneficiaryFromNavigate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const amountValue = tamount;
        const amountValueFloat = parseFloat(amountValue);
        const balanceW1Float = parseFloat(balanceW1);
        if (amountValueFloat >= 100 && amountValueFloat <= balanceW1Float && tamount <= maxLimit) {
            // fetchValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tamount]);
    useEffect(() => {
        if (beneficiary) {
            fetchBenefDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiary]);
    useEffect(() => {
        // console.log('in useEffect');
        if (isFailed) {
            // console.log('in if of useEffect');
            // notify();
            globalNotifyError(isFailedMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFailed]);

    // useEffect hook to select the first option on first render
    useEffect(() => {
        if (isFirstRender && customerMap && customerMap?.length > 0) {
            const filteredSingleCustomer = customerMap?.filter((item) => item?.id == id);
            // console.log(filteredSingleCustomer[0], '-----', customerMap[index]);l
            setSelectedCustomer(filteredSingleCustomer[0]); // Select the default option
        }
        if (location?.state?.data) {
            setSelectedCustomer(location?.state?.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerMap]);

    // useEffect hook to select the first option on first render
    useEffect(() => {
        if (isFirstRender && beneficiaryMap && beneficiaryMap.length > 0) {
            beneficiaryMap?.map((item) => {
                if (item.id === indexBenef) {
                    setChoosedBenef(item); // Select the default option
                }
            });
            // setChoosedBenef(beneficiaryMap[indexBenef]); // Select the default option
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beneficiaryMap]);
    useEffect(() => {
        if (isEmployee === 'true') {
            const merchantEmpID = localStorage.getItem('empId');
            setMerchantEmpID(merchantEmpID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEmployee]);

    return (
        <>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    // flex: Grid,
                    boxShadow: 2,
                    borderRadius: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2,
                        marginBottom: 2
                    }
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={12} md={6} sm={6} xs={12}>
                        <Typography variant="h3">
                            <GlobalBackButton />
                            Domestic Money Transfer{' '}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Link to="/dmt/dmtTable" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Domestic Money Transfer
                    </Link>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Money Transfer</Typography>
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Chip
                                color="info"
                                label={`Selected Customer :  ${
                                    selectedCustomer ? selectedCustomer?.name + ' ' + selectedCustomer?.lname : null
                                }`}
                                sx={{ fontWeight: 'bold' }}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            {/* <Chip color="success" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} /> */}
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            {maxLimitLoading ? (
                                <Chip color="warning" label="Loading..." sx={{}} />
                            ) : (
                                <Chip color="warning" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            {/* <Box sx={{ '& > :not(style)': { m: 1, mb: 4 } }}>
                <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={handleBack}>
                    <ArrowBackIcon sx={{ mr: 1 }} />
                </Fab>
            </Box> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    padding: isMobile ? '20px 10px' : 2
                }}
            >
                {/* <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Chip color="success" label={`Transaction Limit : ₹ ${maxLimit}`} sx={{}} />
                        </Grid>
                    </Grid>
                </Grid> */}

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>{senderno ? 'SENDER MOBILE : ' + senderno : 'SENDER MOBILE : ' + '-'}</h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneAccountNo
                                        ? 'BENEFICIARY A/C : ' + selectedBenefData?.beneAccountNo
                                        : 'BENEFICIARY A/C : ' + '-'}
                                </h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneIfsc
                                        ? 'BENEFICIARY IFSC : ' + selectedBenefData?.beneIfsc
                                        : 'BENEFICIARY IFSC : ' + '-'}
                                </h5>
                            </Grid>
                            <Grid item lg={3} md={6} sm={6} xs={6}>
                                <h5>
                                    {selectedBenefData?.beneMobile
                                        ? 'BENEFICIARY MOBILE : ' + selectedBenefData?.beneMobile
                                        : 'BENEFICIARY MOBILE : ' + '-'}
                                </h5>
                            </Grid>

                            {/* <Stack style={{ paddingLeft: 100, paddingTop: 20 }} direction="row" spacing={2}> */}
                            {/* </Stack> */}
                            {/* <Stack style={{ paddingLeft: 100, paddingTop: 5 }} direction="row" spacing={2}> */}
                            {/* </Stack> */}

                            {/* <Stack style={{ paddingLeft: 70 }} direction="row" spacing={2}> */}

                            {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 0 }}>
                                    </Box>
                                </Stack>
                            </Grid> */}

                            {/* </Stack> */}
                            {/* <Stack style={{ paddingLeft: 70 }} direction="row" spacing={2}> */}

                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    {/* <h3 style={{ paddingLeft: 5, paddingTop: 18 }}>Mode of Transfer: </h3> */}
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 2 }}>
                                        <FormControl fullWidth style={{ minWidth: 200 }}>
                                            <InputLabel htmlFor="demo-simple-select-label">IMPS/NEFT</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select-label"
                                                value={paymentMode}
                                                label="mode"
                                                onChange={handleModeChange}
                                            >
                                                <MenuItem value="IMPS">IMPS</MenuItem>
                                                {/* <MenuItem value="NEFT">NEFT</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack direction="row" spacing={2}>
                                    {/* <h3 style={{ paddingTop: 18 }}>Select beneficiary: </h3> */}
                                    <Box sx={{ minWidth: 200, maxWidth: 200, pt: 2 }}>
                                        {/* <FormControl fullWidth> */}
                                        {/* <InputLabel id="demo-simple-select-label">Beneficiary</InputLabel> */}
                                        <Autocomplete
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={choosedBenef}
                                            label="Beneficiary"
                                            onChange={handleBeneficiaryChange}
                                            options={beneficiaryMap ? beneficiaryMap : []}
                                            getOptionLabel={(option) =>
                                                option?.benefName + ' ' + option?.benefMname + ' ' + option?.benefLname
                                                    ? option?.benefName + ' ' + option?.benefMname + ' ' + option?.benefLname
                                                    : ''
                                            }
                                            renderInput={(params) => <TextField {...params} label="Beneficiary" />}
                                        />
                                        {/* {beneficiaryMap?.map((item) => (                        
                                                    <MenuItem value={item.benefId}>
                                                        {item.benefName + ' / ' + item.benefAcc?.replace(/\d(?=\d{4})/g, '*')}
                                                    </MenuItem>
                                                ))} */}
                                        {/* </Select> */}
                                        {/* </FormControl> */}
                                    </Box>
                                    <Box sx={{ pt: 3, '& > :not(style)': {} }}>
                                        <Tooltip title="Click to add new beneficiary">
                                            <Link to="/dmt/Checkbeneficiary" state={{ data: location?.state?.data }}>
                                                <AntButton shape="circle" type="primary" icon={<AddIcon />} />
                                            </Link>
                                        </Tooltip>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack sx={{ pt: 2 }} direction="row">
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '25ch' }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    ></Box>
                                    {/* <h3 style={{ paddingLeft: 0, paddingTop: 18 }}>Amount: </h3> */}

                                    <TextField
                                        id="outlined-basic"
                                        label="Amount"
                                        variant="outlined"
                                        value={tamount}
                                        error={Boolean(amountError)}
                                        helperText={amountError}
                                        onChange={handleAmountChange}
                                        onBlur={validateAmount}
                                    />
                                    {isTrue ? (
                                        <Space
                                            direction="vertical"
                                            style={{
                                                width: '20%',
                                                paddingTop: 20
                                            }}
                                        >
                                            <Spin size="large">
                                                <div className="content" />
                                            </Spin>
                                        </Space>
                                    ) : null}
                                </Stack>
                            </Grid>
                            {/* </Stack> */}

                            {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                            {/* <Stack sx={{ pt: 6 }} direction="row" spacing={10}> */}
                            {/* <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' }
                            }}
                            noValidate
                            autoComplete="off"
                        ></Box> */}
                            {/* <Box sx={{ padding: 5 }}> */}
                            {/* <Grid container spacing={2}> */}
                            {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Service Charge"
                                    variant="outlined"
                                
                                    value={amountResponse ? amountResponse.newserviceCharge : ''}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Gst"
                              
                                    variant="outlined"
                                    value={amountResponse ? amountResponse?.gst : ''}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Tds"
                                    variant="outlined"
                                
                                    value={amountResponse ? amountResponse.tds : ''}
                                />
                            </Grid> */}
                            {/* </Grid> */}
                            {/* </Stack> */}

                            {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                            {/* <Stack sx={{ pt: 2 }} direction="row" spacing={10}>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' }
                            }}
                            noValidate
                            autoComplete="off"
                        ></Box> */}
                            {/* <h5 style={{ paddingLeft: 0, paddingTop: 18 }}>Effec</h5> */}

                            {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                
                                <TextField
                                    id="outlined-basic"
                                    label="Effective Commission"
                                    variant="outlined"
                                   
                                    value={amountResponse ? amountResponse.gst : ''}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Total Commission"
                                 
                                    variant="outlined"
                                    value={amountResponse ? amountResponse?.totCommi : ''}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Total Debit Amount"
                                    variant="outlined"
                                   
                                    value={amountResponse ? amountResponse.agentDebitAmount : ''}
                                />
                            </Grid> */}
                            {/* </Grid> */}
                            {/* </Grid> */}
                            {/* </Box> */}

                            {/* </Stack> */}
                            {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
                            {/* <Stack direction="row" spacing={2}> */}
                            {/* <h4 style={{ paddingLeft: 40, color: 'green' }}>
                                {isSuccess? 'Transaction Success !' : null}
                            </h4> */}
                            <h4 style={{ paddingLeft: 40, color: 'red' }}>{!isSuccess ? 'Money transfer failed' : null}</h4>
                            <Grid item lg={12} md={6} sm={6} xs={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={Boolean(amountError) || Boolean(isLoading) || isTrue}
                                    // onClick={transferMoney}
                                    onClick={handleTransferClick}
                                    fullWidth
                                >
                                    {isLoading ? 'Loading...' : 'Transfer'}
                                </Button>
                                <Modal
                                    title={<Chip color="info" label={'CONFIRM MONEY TRANSFER DETAILS '} sx={{}} />}
                                    open={open}
                                    onOk={handleOk}
                                    confirmLoading={confirmLoading}
                                    onCancel={handleCancel}
                                >
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <p>Customer Name: {selectedCustomer?.name + ' ' + selectedCustomer?.lname}</p>
                                            <p>Date: {new Date().toDateString().split(' ').slice(1).join(' ')}</p>
                                            <p>Beneficiary A/C : {selectedBenefData ? selectedBenefData?.beneAccountNo : ''}</p>
                                            <p>Service Charge : {amountResponse ? amountResponse.newserviceCharge : ''}</p>
                                            <p>GST : {amountResponse ? amountResponse.gst : ''}</p>
                                            <p>TDS : {amountResponse ? amountResponse.tds : ''}</p>
                                        </Col>

                                        <Col span={12}>
                                            <p>Beneficiary Name: {selectedBenefData?.beneName}</p>
                                            <p>Payment Mode: {paymentMode}</p>
                                            <p>Beneficiary IFSC : {selectedBenefData ? selectedBenefData?.beneIfsc : ''}</p>
                                            <p>Effective Commission : {amountResponse ? amountResponse.agent_commission : ''}</p>
                                            <p>Total Commission : {amountResponse ? amountResponse.totCommi : ''}</p>
                                            <p>Total Debit Amount : {amountResponse ? amountResponse.agentDebitAmount : ''}</p>
                                            {/* <p style={{ fontWeight: 'bold' }}>
                                                Total Debit Amount: {amountResponse ? amountResponse.agentDebitAmount : ''}
                                            </p> */}
                                        </Col>
                                    </Row>
                                    <p style={{ fontWeight: 'bold' }}>
                                        Total Debit Amount: ₹ {amountResponse ? amountResponse.agentDebitAmount : ''}
                                    </p>
                                </Modal>
                            </Grid>
                            {/* </Stack> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal isOpen={merchantModalOpen} handleclose={handleBcModalClose} isPending={isBcConvertPending} />
            )}
            <Spinner loading={isCheckLoading} />
        </>
    );
}
