/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'super-distributor/dashboard',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons2',
            title: 'Profile',
            type: 'item',
            url: 'super-distributor/profile',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons3',
            title: 'Distributors',
            type: 'item',
            url: 'super-distributor/distributors',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons4',
            title: 'Wallet Request',
            type: 'item',
            url: 'super-distributor/walletrequest',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons5',
            title: 'Approve Wallet',
            type: 'item',
            url: 'super-distributor/approvewallet',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons6',
            title: 'Bank Details',
            type: 'item',
            url: 'super-distributor/bankdetails',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons7',
            title: 'Transaction History',
            type: 'item',
            url: 'super-distributor/transaction-history',
            breadcrumbs: true
        }
        //     ]
        // }
    ]
};

export default Dashboard;
