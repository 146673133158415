/* eslint-disable */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstanceLive } from 'instance/Axios';
import { handleCatchError } from 'utils/CatchHandler';
// const type = localStorage.getItem('type');
// export const fetchWalletBalanceAsync = createAsyncThunk('wallet/fetchWalletBalance', async () => {
//     try {
//         const types = localStorage.getItem('type');
//         if (types === 'merchants') {
//             const response = await fetchWalletBalance();
//             return response;
//         }
//     } catch (error) {
//         throw new Error('Failed to fetch wallet balance');
//     }
// });
// console.log(type);
// const loginSlice = createSlice({
//     name: 'login',
//     initialState: {
//         isEmployee: null,
//         status: 'idle',
//         error: null
//     },
//     reducers: {}
// extraReducers: (builder) => {
//     builder
//         .addCase(fetchWalletBalanceAsync.pending, (state) => {
//             state.status = 'loading';
//         })
//         .addCase(fetchWalletBalanceAsync.fulfilled, (state, action) => {
//             state.status = 'succeeded';
//             state.balance = action.payload;
//         })
//         .addCase(fetchWalletBalanceAsync.rejected, (state, action) => {
//             state.status = 'failed';
//             state.error = action.error.message;
//         });
// }
// });

const userSlice = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, action) => action.payload,
        clearUser: (state) => null
    }
});

// export const { increment, decrement } = loginSlice.actions;
// export default loginSlice.reducer;

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
