/* eslint-disable */

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
// material-ui
import { ButtonBase } from '@mui/material';
import { Modal } from 'antd';
import Chip from '@mui/material/Chip';
// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/actions';
import AcemoneyLogo from '../../../assets/images/ACE_MONEY_MASTER_LOGO0.png';
import AcemoneyPremiumLogo from '../../../assets/images/radiantAcemoneyPremiumLogo.png';
import { useState } from 'react';
import { useEffect } from 'react';
import { decrement } from 'redux/WalletSlice';
import { axiosInstanceLive } from 'instance/Axios';
import employee from 'menu-items(employee)/dashboard';
import { getDomainImage } from 'utils/API/Merchant APIs/AuthApiCalls';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const [logo, setLogo] = useState('');
    const defaultId = useSelector((state) => state.customization.defaultId);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [url, setURL] = useState('');
    const [shouldRender, setShouldRender] = useState(false);
    const [open, setOpen] = useState(true);
    const [isPremiumBc, setIsPremiumBc] = useState(false);
    const [premiumLoading, setPremiumLoading] = useState(false);
    const checkRoleApi = async () => {
        setPremiumLoading(true);
        const agentid = localStorage.getItem('id');
        const payload = { agentid };
        const merchantBcCheckResponse = await checkMerchantOrBCAPI(payload);
        const agentType = merchantBcCheckResponse?.data?.agentType;
        if (agentType === 'Premium BC Class 1' || agentType === 'Premium BC Class 2' || agentType === 'Premium BC Class 3') {
            setIsPremiumBc(true);
        } else {
            setIsPremiumBc(false);
        }
        setPremiumLoading(false);
    };
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShouldRender(true);
        }, 1000);

        return () => clearTimeout(timeout);
    }, []);

    //LOGO FETCHING METHOD FROM LOGIN
    // useEffect(() => {
    //     //Fetching logo dynamically from login response (This method is not using anymore)
    //     const logoImage = localStorage.getItem('logoImage');
    //     if (logoImage) {
    //         setLogo(logoImage);
    //     } else {
    //         setLogo(AcemoneyLogo);
    //     }
    // }, []);

    //LOGO FETCHING METHOD FROM DOMAIN USING API
    useEffect(() => {
        //API call to get logo for the corresponding domain name to show in
        const logoFromDomainName = async (domain) => {
            try {
                const payload = { domain };
                const response = await getDomainImage(payload);
                const logoURL = response?.data?.image_url;
                setURL(logoURL);
                localStorage.setItem('LogoImg', logoURL);
            } catch (error) {
                console.log(error);
            }
        };
        const domainName = window.location.protocol + '//' + window.location.hostname;
        if (
            domainName === 'https://dev-app.acemoney.in' ||
            domainName === 'http://localhost' ||
            domainName === 'https://app.acemoney.in' ||
            domainName === 'https://stage-app.acemoney.in'
        ) {
            setURL(AcemoneyLogo);
        } else {
            logoFromDomainName(domainName);
        }
        checkRoleApi();
    }, []);

    const handleButtonClick = () => {
        const loginRole = localStorage.getItem('type'); // Replace with your logic to determine the login role
        const loginSubRole = localStorage.getItem('subrole');
        const employeeRole = localStorage.getItem('employeeRole');
        // Define the redirect URL based on the login role
        let redirectUrl = '';

        if (loginRole === 'merchants') {
            navigate('/merchant-home');
        } else if (loginRole === 'distributor' && !employee) {
            navigate('/distributor/dashboard');
        } else if (loginRole === 'superdistributor') {
            navigate('/super-distributor/dashboard');
        } else if (loginRole === 'admin' && loginSubRole === 'business') {
            // redirectUrl = '/mis/dashboard';
            navigate('/mis/dashboard');
        } else if (loginRole === 'distributor' && employeeRole === 'employee') {
            navigate('/employee/dashboard');
        }

        // Redirect to the appropriate page
        // window.location.href = redirectUrl;
        // navigate(redirectUrl);
    };

    return (
        <>
            {shouldRender && !premiumLoading && (
                <>
                    {url ? (
                        <ButtonBase disableRipple onClick={handleButtonClick}>
                            <img src={isPremiumBc ? AcemoneyPremiumLogo : url} alt="Logo" width="170" />
                        </ButtonBase>
                    ) : (
                        <ButtonBase disableRipple onClick={handleButtonClick}>
                            <img src={isPremiumBc ? AcemoneyPremiumLogo : AcemoneyLogo} alt="Logo" width="170" />
                        </ButtonBase>
                    )}
                    {/* <Modal
                        title={<Chip color="warning" label={'New Message !! '} sx={{}} />}
                        className="custom-modal12"
                        wrapClassName="custom-modal-backdrop12"
                        centered
                        open={open}
                        onOk={() => setOpen(false)}
                        onCancel={() => setOpen(false)}
                        width={700}
                        // footer={true}
                        // closable={true}
                        // maskClosable={false}
                    >
                     
                        <div style={{ display: 'flex', justifyContent: 'center', height: '200px' }}></div>
                    </Modal> */}
                </>
            )}
        </>
    );
};

export default LogoSection;
