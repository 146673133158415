import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import { Button } from 'antd';
import { axiosInstanceLive } from '../../../instance/Axios';
// import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { message, Popconfirm } from 'antd';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
const commonStyles = {
    m: 0
};
import { useState } from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { fetchWalletBalanceAsync } from 'redux/WalletSlice';
import { useDispatch } from 'react-redux';
import { handleCatchError } from 'utils/CatchHandler';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { globalNotifyError } from 'views/utilities/AlertToast';
// import { Navigate } from 'react-router';
export default function CheckBeneficiaryStatus() {
    const dispatch = useDispatch();
    const email = localStorage.getItem('email_id');
    const senderno = localStorage.getItem('sendernumber');
    const address = localStorage.getItem('address');
    const pin = localStorage.getItem('pin');
    const city = localStorage.getItem('city');
    const transfername = 'fino-transfer';
    const [AccountNo, setAccountNo] = useState('');
    let [benefname, setBenefname] = useState('');
    let [benelname, setBenelname] = useState('');
    const [MobileNo, setMobileNo] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [title, setTitle] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [accError, setAccError] = useState('');
    const [ifscError, setIfscError] = useState('');
    const [dataFromPennyDrop, setDataFromPennyDrop] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [isLoadingPenny, setIsLoadingPenny] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.item || location?.state?.data;
    const firstName = dataFromPennyDrop?.BeneFName;
    const lastName = dataFromPennyDrop?.BeneLName;
    // const phoneNumber = dataFromPennyDrop?.MobileNo;

    // const filteredData = location?.state?.filteredData;
    // const index = location?.state?.index;
    // console.log('location.state :', location.state);
    // const agent = localStorage.getItem('id');
    // console.log(data);
    // const senderno = data?.customermob || location?.state?.search;
    // console.log('sendero : ', senderno);
    // console.log(email, senderno, AccountNo, ifsc);
    // useEffect(() => {

    //Alert antd
    const errorExists = () => {
        // console.log('shdsuhsehfsdiufh');
        messageApi.open({
            type: 'warning',
            content: 'Account verified successfully, but the customer already exists !',
            style: {
                marginTop: '20vh'
            },
            duration: 5
        });
    };

    //API CALL FUNCTIONS

    //API TO CREATE BENEFICIARY
    const createBenes = async (dataAfterDrop) => {
        const firstName = dataAfterDrop?.BeneFName;
        const lastName = dataAfterDrop?.BeneLName;
        if (firstName && lastName) {
            benefname = firstName;
            benelname = lastName;
        }
        const token = localStorage.getItem('token');
        try {
            // setIsLoadingCreate(true);
            const response = await axiosInstanceLive.post(
                '/api/V2/moneytransfer/createBeneficiaryApi',
                { email, senderno, AccountNo, ifsc, MobileNo, pin, title, address, city, benefname, benelname, transfername },

                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const status = response?.data?.status;
            setIsLoading(false);
            // setIsLoadingCreate(false);
            if (status) {
                setMobileNo('');
                setBenefname('');
                setBenelname('');
                setIfsc('');
                setAccountNo('');
                setTitle('');
                navigate('/dmt/dmtTransfer', { state: { status, data, senderno, created: 1 } });
            }
        } catch (error) {
            // setIsLoadingCreate(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //API TO CHECK THE BENEFICIARY EXISTS OR NOT
    const checkbeneficiary = async () => {
        // console.log('first');
        const token = localStorage.getItem('token');
        try {
            if (benefname && benelname && AccountNo && ifsc && MobileNo && !errorMsg) {
                if (!accError && !ifscError) {
                    if (title) {
                        setIsLoading(true);
                        const response = await axiosInstanceLive.post(
                            '/api/V2/moneytransfer/checkBenfExistApi',
                            { email, senderno, AccountNo, ifsc },

                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        // console.log('check benef response :', response);
                        // console.log(response.data);
                        const Datas = response?.data;
                        const status = Datas?.status;
                        // setShowStatus(status);
                        if (status) {
                            setIsLoading(false);
                            globalNotifyError('The beneficiary already exist !');
                        } else {
                            createBenes();
                        }
                        // console.log('status', status);
                        // setShowResponse(status);
                        if (AccountNo && ifsc && MobileNo) {
                            if (!accError && !ifscError) {
                                if (status === false) {
                                    // console.log('show res', showResponse);
                                    // createBene();
                                    // pennydropFunction();
                                    // navigate('/dmt/CreateBeneficiary', { state: { AccountNo, ifsc, status, data, senderno } });
                                    // setShowCreate(true);
                                } else {
                                    // setIsTrue('true');
                                }
                            }
                        } else {
                            // setAccError('Please enter a valid Account Number');
                            // setIfscError('Please enter a valid IFSC Code');
                            // setErrorMsg('Please enter a valid Mobile Number');
                        }
                    } else {
                        globalNotifyError('Please choose a title (Mrs,Mr or Ms).');
                    }
                }
            } else {
                globalNotifyError('Please fill all the fields properly.');
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Function to call api for penny drop
    const pennydropFunction = async () => {
        const token = localStorage.getItem('token');
        try {
            setIsLoadingPenny(true);
            const Type = 'fino';
            const Senderno = senderno;
            const customerId = data?.id ? data?.id : data?.data[0]?.id;
            const response = await axiosInstanceLive.post(
                'api/V2/moneytransfer/verifyBenefincier',
                { Type, Senderno, AccountNo, ifsc, customerId },

                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            setIsLoadingPenny(false);
            dispatch(fetchWalletBalanceAsync());
            // console.log('penny drop res :', response);
            if (response?.data?.success) {
                const verifiedData = response?.data?.data;
                setDataFromPennyDrop(verifiedData);
                const exists = response?.data?.data?.exists;
                if (!accError && !ifscError) {
                    if (!exists) {
                        // console.log('exists', exists);
                        // navigate('/dmt/CreateBeneficiary', { state: { AccountNo, ifsc, status, data, senderno, verifiedData } });
                        setShowCreate(true);
                        createBenes(verifiedData);
                    } else {
                        // console.log('exists true');
                        errorExists();
                    }
                }
            }
        } catch (error) {
            console.log(error.message);
            handleCatchError(error);
        }
    };

    //Helpers for pennydrop confirmation
    const confirm = () => {
        if (benefname && benelname && AccountNo && ifsc && MobileNo && !errorMsg && !ifscError && !accError) {
            pennydropFunction();
        } else {
            globalNotifyError('Please fill all the required fields properly.');
        }
    };
    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    // localStorage.setItem('AccontNo', AccountNo);
    // localStorage.setItem('ifsc', ifsc);

    // }, []);
    // function createBene() {
    //     navigate('/dmt/CreateBeneficiary', { state: { AccountNo, ifsc, showStatus } });
    // }

    // Validation before create customer
    // function handleSubmit() {
    //     if (MobileNo && AccountNo && ifsc && title && benefname && benelname) {
    //         createBenes();
    //     } else {
    //         // setError(true);
    //         globalNotifyError('All fields are Required!');
    //     }
    // }
    const handleBenefFNameChange = (event) => {
        const regex = /^[a-zA-Z\s]*$/;
        const firstName = event.target.value;
        if (regex.test(firstName) || firstName === '') {
            if (firstName.length <= 25) setBenefname(firstName);
        }
    };
    const handleBenefLNameChange = (event) => {
        const regex = /^[a-zA-Z\s]*$/;
        const lastName = event.target.value;
        if (regex.test(lastName) || lastName === '') {
            if (lastName.length <= 25) setBenelname(lastName);
        }
    };
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };

    //FUNCTION TO HANDLE VALIDATION ERROR
    const validateMobile = () => {
        const re = /^[0-9]{10}$/; // Regular expression to match 10 digit mobile number
        if (!re.test(MobileNo)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };

    const validateAccount = () => {
        // const re = /^[0-9]{9,18}$/; // Regular expression to match 10 digit mobile number
        if (!AccountNo) {
            setAccError('Please enter a valid Account Number');
        } else {
            setAccError('');
        }
    };

    const handleAccountChange = (e) => {
        const regex = /^\d{0,17}$/;
        const inputValue = e.target.value;
        if (regex.test(inputValue) || inputValue === '') {
            setAccountNo(inputValue);
        }
    };
    const validateIfsc = () => {
        const re = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
        if (!re.test(ifsc)) {
            setIfscError('Please enter a valid IFSC code');
        } else {
            setIfscError('');
        }
    };

    const handleIfscChange = (e) => {
        const alphanumericRegex = /^[a-zA-Z0-9]+$/;
        const ifscInput = e.target.value;
        if (alphanumericRegex.test(ifscInput) || ifscInput === '') setIfsc(ifscInput.toUpperCase());
    };

    const handleMobileNumberChange = (event) => {
        const mobileInput = event.target.value;
        if (mobileInput === '' || /^[6-9]\d{0,9}$/.test(mobileInput)) {
            setMobileNo(mobileInput);
        } else {
            globalNotifyError('Please enter a valid phone number starting with 6, 7, 8, or 9 and with a maximum of 10 digits.');
        }
    };

    //toast functions

    useEffect(() => {
        const checkIsMerchantOrBc = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);
            const agentid = localStorage.getItem('id');

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            // const globalMerchantPermission = useSelector((state) => state);
            // console.log('globalMerchantPermission', globalMerchantPermission);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus) {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        checkIsMerchantOrBc();
        //To automatically fill the fields in create benef

        if (showCreate) {
            // setIfsc(ifscCheck);
            // setAccountNo(accno);
            // setAllData(verifiedData);
            setBenefname(firstName);
            setBenelname(lastName);
            // setMobileNo(phoneNumber);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showCreate, dataFromPennyDrop]);

    return (
        <>
            {contextHolder}
            {/* <Box sx={{ '& > :not(style)': { m: 1, mb: 4 } }}>
                    <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={handleBack}>
                        <ArrowBackIcon sx={{ mr: 1 }} />
                    </Fab>
                </Box> */}
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    marginBottom: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton />
                    Domestic Money Transfer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <Link to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>

                    <Link to="/dmt/dmtTransfer" state={{ data }} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography component="span">View Customer</Typography>
                    </Link>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Check Beneficiary</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    padding: 2,
                    mb: 1
                }}
            >
                <h2>Check Beneficiary</h2>
                {/* {showCreate ? ( */}
                <RadioGroup
                    sx={{
                        mt: 2,
                        ml: 1
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        value="Mrs"
                        control={<Radio />}
                        label="Mrs."
                        checked={title === 'Mrs'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Mr"
                        control={<Radio />}
                        label="Mr."
                        checked={title === 'Mr'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                    <FormControlLabel
                        value="Ms"
                        control={<Radio />}
                        label="Ms."
                        checked={title === 'Ms'}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </RadioGroup>
                {/* ) : null} */}
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="First Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={benefname}
                                    onChange={handleBenefFNameChange}
                                    // onBlur={validateAadhaar}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="Last Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={benelname}
                                    onChange={handleBenefLNameChange}
                                    // helperText={errorMsg}
                                    // error={Boolean(errorMsg)}
                                    // onBlur={validateMobile}
                                    // onChange={handleMobileChange}
                                />
                            </Grid>
                        </>
                        <Grid item s={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Account Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={AccountNo}
                                helperText={accError}
                                error={Boolean(accError)}
                                onChange={handleAccountChange}
                                onBlur={validateAccount}
                            />
                        </Grid>
                        <Grid item s={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="IFSC Code"
                                variant="outlined"
                                fullWidth
                                required
                                value={ifsc}
                                helperText={ifscError}
                                error={Boolean(ifscError)}
                                onChange={handleIfscChange}
                                onBlur={validateIfsc}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={MobileNo}
                                onChange={handleMobileNumberChange}
                                helperText={errorMsg}
                                error={Boolean(errorMsg)}
                                onBlur={validateMobile}
                            />
                        </Grid>
                        {/* {showCreate ? (
                            <>
                                <Grid item xs={12} sm={6} lg={6}></Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Button type="primary" size="large" fullWidth loading={isLoadingCreate} onClick={handleSubmit}>
                                        Create
                                    </Button>
                                </Grid>
                            </>
                        ) : ( */}
                        <>
                            <Grid item xs={12} sm={6} lg={6}></Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Button
                                    variant="contained"
                                    type="primary"
                                    size="middle"
                                    loading={isLoading}
                                    fullWidth
                                    onClick={checkbeneficiary}
                                    sx={{ mt: 3 }}
                                >
                                    Proceed Without Penny Drop
                                </Button>
                                {/* {showResponse === 'true' ? <h3 color="red">User Already Exist!!!</h3> : ''} */}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Popconfirm
                                    title="Verify account by dropping a penny into it !"
                                    description="Using Penny Drop may cost you ₹3."
                                    onConfirm={confirm}
                                    onCancel={cancel}
                                    okText="Proceed"
                                    cancelText="Cancel"
                                >
                                    <Button
                                        size="middle"
                                        variant="contained"
                                        type="primary"
                                        loading={isLoadingPenny}
                                        fullWidth
                                        sx={{ mt: 3 }}
                                    >
                                        Verify With Penny Drop
                                    </Button>
                                </Popconfirm>
                            </Grid>
                        </>
                        {/* )} */}
                    </Grid>
                </Box>
            </Box>

            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    serviceName={'dmt'}
                />
            )}

            <Spinner loading={isCheckLoading} />
        </>
    );
}
