/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Wallets = {
    id: 'newUtilities',
    // title: 'distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'distributor/dashboard',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons2',
            title: 'Profile',
            type: 'item',
            url: 'distributor/profile',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons3',
            title: 'Merchants',
            type: 'item',
            url: 'distributor/merchants',
            breadcrumbs: false
        },
        {
            id: 'icons2211',
            title: 'Employee',
            type: 'collapse',
            children: [
                {
                    id: 'sample-page211',
                    title: 'Create Employee',
                    type: 'item',
                    url: '/distributor/createemployee',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons521',
                    title: 'List Employee',
                    type: 'item',
                    url: '/distributor/listemployee',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'tabler-icons4',
            title: 'Wallet Request',
            type: 'item',
            url: 'distributor/walletrequest',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons5',
            title: 'Approve Wallet',
            type: 'item',
            url: '/distributor/approvewallet',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons6',
            title: 'Bank Details',
            type: 'item',
            url: '/distributor/bankdetails',
            breadcrumbs: false
        },
        {
            id: 'tabler-icons7',
            title: 'Transaction History',
            type: 'item',
            url: '/distributor/transaction-history',
            breadcrumbs: false
        }
        // ]
        // }
    ]
};

export default Wallets;
