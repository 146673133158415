import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ModifiedLoginPage')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/auth-forms/forgotPassword')));
const ResetPassword2 = Loadable(lazy(() => import('views/pages/authentication/auth-forms/ForgotPassword2')));
const LoginFromRedirect = Loadable(lazy(() => import('views/pages/authentication/authentication3/LoginFromRedirect')));
const Unauthorized = Loadable(lazy(() => import('views/pages/authentication/authentication3/UnauthorizedPage')));
const MpinCreation = Loadable(lazy(() => import('views/pages/authentication/M-pin/Mpin')));
const BcAgreeSuccessRedirect = Loadable(lazy(() => import('views/Components/BcAgreementSuccessPage')));
const BcAgreeCancelRedirect = Loadable(lazy(() => import('views/Components/BcAgreementCancelPage')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/resetpassword',
            element: <ResetPassword />
        },
        {
            path: '/resetpassword2',
            element: <ResetPassword2 />
        },
        {
            path: '/loginfromredirect',
            element: <LoginFromRedirect />
        },
        {
            path: '/unauthorized',
            element: <Unauthorized />
        },
        {
            path: '/mpin',
            element: <MpinCreation />
        },
        {
            path: '/bcAgreeSuccess',
            element: <BcAgreeSuccessRedirect />
        },
        {
            path: '/bcAgreeCancel',
            element: <BcAgreeCancelRedirect />
        }
    ]
};

export default AuthenticationRoutes;
