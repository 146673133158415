/* eslint-disable */

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoute from './ProtectRoute';
import ProtectedRouteDistributor from './ProtectRouteDistributor';
import ProtectedRouteSuperDistributor from './ProtectRouteSuperDistributor';
import ProtectedRouteMIS from './ProtectRouteMIS';
import ProtectedRouteEmployee from './ProtectedRouteEmployee';
import { Wallet } from '@mui/icons-material';
import {
    ConditionalRenderDmtTable,
    ConditionalRenderCreateCustomer,
    ConditionalRenderDmtTransfer,
    ConditionalRenderCheckBenef,
    ConditionalRenderTransferPage
} from 'views/ConditionalRender';
import BillInvoice from 'views/BillsAndEmi/Bill-Reciept/BillInvoice';
// import Aeps_Authentication from 'views/wallets/AEPS/Aeps_Authentication';
// import { dmtType } from '../layout/MainLayout/index';
//Data from local storage
const dmtType = localStorage.getItem('dmtType');
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

//PUBLIC MESSAGE PAGE ROUTES
const PublicMessage = Loadable(lazy(() => import('views/PublicMessage')));
const PrimaryProfileUpdate = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/PrimaryProfileUpdate')));
const MandatoryPasswordReset = Loadable(lazy(() => import('views/Components/MandatoryResetPasswordModal')));

//MONEY TRANSFER PAGES ROUTING
const DmtTable = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtTable')));
const DmtTableIserveu = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/DmtTable')));
const DmtTransfer = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtTransfer')));
const CheckBeneficiaryStatus = Loadable(lazy(() => import('views/bankingServices/money transfer/CheckBeneficiaryStatus')));
const ListAllBeneficiary = Loadable(lazy(() => import('views/bankingServices/money transfer/ListAllBeneficiary')));
const FundTransfer = Loadable(lazy(() => import('views/bankingServices/money transfer/TransferPage')));
const ListMoneyTransfer = Loadable(lazy(() => import('views/bankingServices/money transfer/ListMoneyTransfer')));
const CreateBeneficiary = Loadable(lazy(() => import('views/bankingServices/money transfer/CreateBeneficiary')));
const CreateCustomer = Loadable(lazy(() => import('views/bankingServices/money transfer/CreateCustomer')));
const ReceiptPage = Loadable(lazy(() => import('views/bankingServices/money transfer/ReceiptPage')));
const DmtReport = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtReport')));
const ReportReciept = Loadable(lazy(() => import('views/bankingServices/money transfer/DmtReceipt')));
const CreateCustomerIservue = Loadable(lazy(() => import('views/bankingServices/money transfer/DMTIserveu/CreateCustomer')));
//AEPS PAGES ROUTING
const AadhaarPay = Loadable(lazy(() => import('views/bankingServices/AEPS/AadhaarPay')));
const BalanceEnquiry = Loadable(lazy(() => import('views/bankingServices/AEPS/BalanceEnquiry')));
const CashWithdrawal = Loadable(lazy(() => import('views/bankingServices/AEPS/CashWithdrawal')));
const MiniStatementAadhaar = Loadable(lazy(() => import('views/bankingServices/AEPS/MiniStatementAadhaar')));
const MiniStatementReciept = Loadable(lazy(() => import('views/bankingServices/AEPS/Reciept/MiniStatementReciept')));
const BalanceEnquiryReciept = Loadable(lazy(() => import('views/bankingServices/AEPS/Reciept/BalanceEnquiryReciept')));
const AepsReport = Loadable(lazy(() => import('views/bankingServices/AEPS/AepsReport')));
const Aeps_Authentication = Loadable(lazy(() => import('views/bankingServices/AEPS/Aeps_Authentication')));
const AadhaarpayReport = Loadable(lazy(() => import('views/bankingServices/AEPS/AadhaarPayReport')));
const Kycauthentication = Loadable(lazy(() => import('views/bankingServices/AEPS/aepsKycAuthentication')));
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
//BBPS
const ElectricityBill = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/ElectricityBill')));
const WaterBill = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/WaterBill')));
const BillPaymentsReport = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BillPaymentsReport')));
const BbpsRegistration = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BbpsRegistration/BbpsRegistration')));
const BillPayments = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BillPayments')));
const Recharges = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Mobile/RechargePage')));
const RechargeReport = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Mobile/RechargeReport')));
const BbpsReceipt = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BbpsRecieptPage')));
const RechargeReceipt = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Mobile/RechargeReceipt')));
const BillReceipt = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillReciept')));
const BillPrint = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillPrint')));
const BillList = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillJist')));
const BillInvoices = Loadable(lazy(() => import('views/BillsAndEmi/Bill-Reciept/BillInvoice')));
//DTH
const DthPage = Loadable(lazy(() => import('views/BillsAndEmi/Recharges/Dth/RechargePage')));
//icici registration
const Merchantmodel = Loadable(lazy(() => import('views/iciciRegistration/MerchantModel')));
const Address = Loadable(lazy(() => import('views/iciciRegistration/Address')));
const KYC = Loadable(lazy(() => import('views/iciciRegistration/kyc')));
const IciciPending = lazy(() => import('views/iciciRegistration/iciciUnauthorized/IciciPending'));
//Iserveu MATM registration
const IserveuMATMRegistration = Loadable(lazy(() => import('views/bankingServices/MATM/Iserveu-MATM-Registration')));
const MatmReport = Loadable(lazy(() => import('views/bankingServices/MATM/MatmReport')));
//Common Services
const CommonService = Loadable(lazy(() => import('views/CommonService/GovermentServices/GovermentServices')));
const SubServices = Loadable(lazy(() => import('views/CommonService/GovermentServices/SubServices')));
const InsuranceRedirect = Loadable(lazy(() => import('views/CommonService/InsuranceRedirect')));
const InsuranceComingsoon = Loadable(lazy(() => import('views/CommonService/InsuranceComingsoonPage')));
const HelpingTools = Loadable(lazy(() => import('views/CommonService/HelpingTools/HelpingTools')));
const InsuranceReport = Loadable(lazy(() => import('views/CommonService/InsuranceReport')));

//PANCARD
const RegisterPancard = Loadable(lazy(() => import('views/Pancard/PancardPage')));
const BuyCouponPage = Loadable(lazy(() => import('views/Pancard/BuyCouponPage')));
const ViewCouponPage = Loadable(lazy(() => import('views/Pancard/ViewCouponPage')));
const ViewRegistrationPage = Loadable(lazy(() => import('views/Pancard/ViewRegDetails')));
const EditResubmitPage = Loadable(lazy(() => import('views/Pancard/EditResubmitPage')));

//Bookings
const TrainTicket = Loadable(lazy(() => import('views/BillsAndEmi/trainTickets/BookTrainTicket')));

//CUSTOMER SUPPORT SECTION
const TicketManagement = Loadable(lazy(() => import('views/CustomerSupport/TicketManagement')));
const SupportChat = Loadable(lazy(() => import('views/CustomerSupport/CustomerChat')));

//atm upi
const AtmUpi = Loadable(lazy(() => import('views/bankingServices/UpiAtm/UpiAtm')));
const AtmUpiHistory = Loadable(lazy(() => import('views/bankingServices/UpiAtm/UpiAtmHistory')));
const AtmUpiMessage = Loadable(lazy(() => import('views/bankingServices/UpiAtm/upiAtmUnFreeze')));
//BC or premimum BC conversion
const UpgradePremiumBc = Loadable(lazy(() => import('views/Others/PremiumBC/UpgradePremiumBc')));
const PremumBcClassCard = Loadable(lazy(() => import('views/Others/PremiumBC/ClassCards')));
//POWER BI REPORTS
const PowerBiReport = Loadable(lazy(() => import('views/Components/PowerBi/PowerBiReport')));

//Super Distributor
const DashboardSuperDistributor = Loadable(lazy(() => import('views/super-distributor/Dashboard')));
const ProfileSuperDistributor = Loadable(lazy(() => import('views/super-distributor/Profile')));
const DistributorsSuperDistributor = Loadable(lazy(() => import('views/super-distributor/Distributors')));
const ViewSuperDistributor = Loadable(lazy(() => import('views/super-distributor/ViewDistributor')));
const ListAgents = Loadable(lazy(() => import('views/super-distributor/ListAgents')));
const TransactionAgents = Loadable(lazy(() => import('views/super-distributor/TransactionAgents')));
const EditDistributor = Loadable(lazy(() => import('views/super-distributor/EditDistributor')));
const CreateDistributor = Loadable(lazy(() => import('views/super-distributor/CreateDistributor')));
const WalletRequestSuper = Loadable(lazy(() => import('views/super-distributor/WalletRequest')));
const ApproveWalletSuper = Loadable(lazy(() => import('views/super-distributor/ApproveWallet')));
const BankDetailsSuper = Loadable(lazy(() => import('views/super-distributor/BankDetails')));
const UpiWalletRequest = Loadable(lazy(() => import('views/super-distributor/UpiWalletRequest')));
const WalletRequestQr = Loadable(lazy(() => import('views/super-distributor/WalletRequestQr')));
const TransactionHistorySuper = Loadable(lazy(() => import('views/super-distributor/TransactionHistory')));
const Debit = Loadable(lazy(() => import('views/super-distributor/Debit')));
//Distributor
const DashboardDistributor = Loadable(lazy(() => import('views/Distributor/Dashboard')));
const Profile = Loadable(lazy(() => import('views/Distributor/Profile')));
const WalletRequest = Loadable(lazy(() => import('views/Distributor/WalletRequest')));
const BankDetails = Loadable(lazy(() => import('views/Distributor/BankDetails')));
const ApproveWallet = Loadable(lazy(() => import('views/Distributor/ApproveWallet')));
const Merchants = Loadable(lazy(() => import('views/Distributor/Merchants')));
const AddMerchants = Loadable(lazy(() => import('views/Distributor/AddMerchants')));
const ViewMerchants = Loadable(lazy(() => import('views/Distributor/ViewMerchants')));
const TransactionMerchant = Loadable(lazy(() => import('views/Distributor/TransactionMerchant')));
const EditMerchant = Loadable(lazy(() => import('views/Distributor/EditMerchant')));
const TransactionHistory = Loadable(lazy(() => import('views/Distributor/TransactionHistory')));
const CreateEmployee = Loadable(lazy(() => import('views/Distributor/CreateEmployee')));
const ListEmployee = Loadable(lazy(() => import('views/Distributor/ListEmployee')));
const QronboardDist = Loadable(lazy(() => import('views/Distributor/merchantTable/QrOnboard')));
const PosOnboardDist = Loadable(lazy(() => import('views/Distributor/merchantTable/PosOnboard')));
const OnBoardingStatusDist = Loadable(lazy(() => import('views/Distributor/merchantTable/OnboardingStatus')));
//mis
const Dashboardmis = Loadable(lazy(() => import('views/mis/Dashboard')));
const QrMerchants = Loadable(lazy(() => import('views/mis/QrMerchants')));
const Wallets = Loadable(lazy(() => import('views/mis/Wallets')));
const CoOperative = Loadable(lazy(() => import('views/mis/CoOperative')));
const CoOperativeBc = Loadable(lazy(() => import('views/mis/CoOperativeBc')));
const UpiPayOuts = Loadable(lazy(() => import('views/mis/UpiPayOuts')));
const MerchantThroughCoOperative = Loadable(lazy(() => import('views/mis/MerchantsThroughCo')));
const BcPoints = Loadable(lazy(() => import('views/mis/BcPoints')));

//merchants

const Transactions = Loadable(lazy(() => import('../views/BalanceSection/BalanceToRedeem')));
const Wallet3 = Loadable(lazy(() => import('../views/BalanceSection/Wallet3')));
const SettlementRequest = Loadable(lazy(() => import('../views/BalanceSection/SettlementRequest')));
const LoadWallet = Loadable(lazy(() => import('../views/BalanceSection/LoadWallet')));
const UpiWalletRequestMerchant = Loadable(lazy(() => import('../views/BalanceSection/UpiWalletRequest')));
const WalletRequestQrUpi = Loadable(lazy(() => import('../views/BalanceSection/WalletRequestQr')));
const ProfilePage = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/ProfilePage')));
const EditProfile = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/EditProfile')));
const AllTransactions = Loadable(lazy(() => import('../views/BalanceSection/AllTransactions')));
// pos
const PosReport = Loadable(lazy(() => import('../views/bankingServices/POS/POSReport1')));
//Special pages
const UnauthorizedSection = Loadable(lazy(() => import('views/pages/authentication/authentication3/UnauthorizedSection')));
//Mobile banking
const ApiRegistration = Loadable(lazy(() => import('views/bankingServices/MobileBanking/ApiRegistration')));
//Qr
const Qr = Loadable(lazy(() => import('views/bankingServices/QR/Qr')));
//Rd services
const RDServices = Loadable(lazy(() => import('views/CommonService/RDService/RDService')));
const RDServices2 = Loadable(lazy(() => import('views/CommonService/RDService/RDService2')));
const RDServices3 = Loadable(lazy(() => import('views/CommonService/RDService/RDService3')));
const RDServices4 = Loadable(lazy(() => import('views/CommonService/RDService/RDServices4')));

//HelpingTools
const HelpLine = Loadable(lazy(() => import('views/HelpLine/HelpLine')));
const TestIframe = Loadable(lazy(() => import('views/Test/IframeTest')));
//bbps Auth
const BbpsForm = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BBPSAuthuntication/BbpsAuthForm')));
const Bbpspending = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BBPSAuthuntication/BbpsPending')));
const BbpsAuth = Loadable(lazy(() => import('views/BillsAndEmi/BBPS/BBPSAuthuntication/BBPSAuthuntication')));
const BbpsOnBoardWidget = Loadable(lazy(() => import('views/dashboard/Default/BbpsAuthFormWidget')));
const BbpsOnboardPending = Loadable(lazy(() => import('views/dashboard/Default/BbpsPending')));
//Employee Login
const EmployeeDashboard = Loadable(lazy(() => import('views/Employee/Dashboard')));
const EmployeeProfile = Loadable(lazy(() => import('views/Employee/Profile')));
const CreateMerchant = Loadable(lazy(() => import('views/Employee/CreateMerchant')));
const ListMerchants = Loadable(lazy(() => import('views/Employee/ListMerchants')));
const ViewEmployeeMerchant = Loadable(lazy(() => import('views/Employee/ViewMerchants')));
const EmployeeTransactionMerchant = Loadable(lazy(() => import('views/Employee/TransactionMerchant')));
const EmployeeEditMerchant = Loadable(lazy(() => import('views/Employee/EditMerchant')));
const Qronboard = Loadable(lazy(() => import('views/Employee/merchantTable/QrOnboard')));
const PosOnboard = Loadable(lazy(() => import('views/Employee/merchantTable/PosOnboard')));
const OnBoardingStatus = Loadable(lazy(() => import('views/Employee/merchantTable/OnboardingStatus')));

//MERCHANT EMPLOYEE >>>>>>>>>>>
const CreateMerchantEmployee = Loadable(lazy(() => import('../views/MerchantEmployee/CreateMerchantEmployee')));

// ==============================|| MAIN ROUTING ||============================== //

const DistributorRoutes = {
    path: '',
    // element: <AdminLayout />,
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteDistributor element={<DashboardDistributor />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteDistributor element={<Profile />} />
        },
        {
            path: 'walletrequest',
            element: <ProtectedRouteDistributor element={<WalletRequest />} />
        },
        {
            path: 'bankdetails',
            element: <ProtectedRouteDistributor element={<BankDetails />} />
        },
        {
            path: 'approvewallet',
            element: <ProtectedRouteDistributor element={<ApproveWallet />} />
        },
        {
            path: 'merchants',
            element: <ProtectedRouteDistributor element={<Merchants />} />
        },
        {
            path: 'addmerchants',
            element: <ProtectedRouteDistributor element={<AddMerchants />} />
        },
        {
            path: 'view-merchants',
            element: <ProtectedRouteDistributor element={<ViewMerchants />} />
        },
        {
            path: 'transaction-merchant',
            element: <ProtectedRouteDistributor element={<TransactionMerchant />} />
        },
        {
            path: 'indexeditmerchants',
            element: <ProtectedRouteDistributor element={<EditMerchant />} />
        },
        {
            path: 'transaction-history',
            element: <ProtectedRouteDistributor element={<TransactionHistory />} />
        },
        {
            path: 'createemployee',
            element: <ProtectedRouteDistributor element={<CreateEmployee />} />
        },
        {
            path: 'listemployee',
            element: <ProtectedRouteDistributor element={<ListEmployee />} />
        },
        {
            path: 'qronboard',
            element: <ProtectedRouteDistributor element={<QronboardDist />} />
        },
        {
            path: 'posonboard',
            element: <ProtectedRouteDistributor element={<PosOnboardDist />} />
        },
        {
            path: 'status',
            element: <ProtectedRouteDistributor element={<OnBoardingStatusDist />} />
        }
    ]
};

const SuperdistributorRoutes = {
    path: '',
    // element: <AdminLayout />,
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteSuperDistributor element={<DashboardSuperDistributor />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteSuperDistributor element={<ProfileSuperDistributor />} />
        },
        {
            path: 'distributors',
            element: <ProtectedRouteSuperDistributor element={<DistributorsSuperDistributor />} />
        },
        {
            path: 'view-distributors',
            element: <ProtectedRouteSuperDistributor element={<ViewSuperDistributor />} />
        },
        {
            path: 'list-agents',
            element: <ProtectedRouteSuperDistributor element={<ListAgents />} />
        },
        {
            path: 'transactions-agent',
            element: <ProtectedRouteSuperDistributor element={<TransactionAgents />} />
        },
        {
            path: 'edit-distributor',
            element: <ProtectedRouteSuperDistributor element={<EditDistributor />} />
        },
        {
            path: 'create-distributor',
            element: <ProtectedRouteSuperDistributor element={<CreateDistributor />} />
        },
        {
            path: 'walletrequest',
            element: <ProtectedRouteSuperDistributor element={<WalletRequestSuper />} />
        },
        {
            path: 'approvewallet',
            element: <ProtectedRouteSuperDistributor element={<ApproveWalletSuper />} />
        },
        {
            path: 'bankdetails',
            element: <ProtectedRouteSuperDistributor element={<BankDetailsSuper />} />
        },
        {
            path: 'upi-wallet-request',
            element: <ProtectedRouteSuperDistributor element={<UpiWalletRequest />} />
        },
        {
            path: 'qr-wallet-request',
            element: <ProtectedRouteSuperDistributor element={<WalletRequestQr />} />
        },
        {
            path: 'transaction-history',
            element: <ProtectedRouteSuperDistributor element={<TransactionHistorySuper />} />
        },
        {
            path: 'debit',
            element: <ProtectedRouteSuperDistributor element={<Debit />} />
        }

        // {
        //     path: 'addbankdetails',
        //     element: <ProtectedRoute element={<AddBankDetailsSuper />} />
        // }
    ]
};
const EmployeeRoutes = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteEmployee element={<EmployeeDashboard />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteEmployee element={<EmployeeProfile />} />
        },
        {
            path: 'Create-Merchant',
            element: <ProtectedRouteEmployee element={<CreateMerchant />} />
        },
        {
            path: 'list-merchant',
            element: <ProtectedRouteEmployee element={<ListMerchants />} />
        },
        {
            path: 'view-merchants',
            element: <ProtectedRouteDistributor element={<ViewEmployeeMerchant />} />
        },
        {
            path: 'transaction-merchant',
            element: <ProtectedRouteDistributor element={<EmployeeTransactionMerchant />} />
        },
        {
            path: 'indexeditmerchants',
            element: <ProtectedRouteDistributor element={<EmployeeEditMerchant />} />
        },
        {
            path: 'qronboard',
            element: <ProtectedRouteDistributor element={<Qronboard />} />
        },
        {
            path: 'posonboard',
            element: <ProtectedRouteDistributor element={<PosOnboard />} />
        },
        {
            path: 'status',
            element: <ProtectedRouteDistributor element={<OnBoardingStatus />} />
        }
    ]
};
const MisRoutes = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteMIS element={<Dashboardmis />} />
        },

        {
            path: 'qrmerchants',
            element: <ProtectedRouteMIS element={<QrMerchants />} />
        },
        {
            path: 'wallets',
            element: <ProtectedRouteMIS element={<Wallets />} />
        },
        {
            path: 'cooperative',
            element: <ProtectedRouteMIS element={<CoOperative />} />
        },
        {
            path: 'cooperativebc',
            element: <ProtectedRouteMIS element={<CoOperativeBc />} />
        },
        {
            path: 'upipayouts',
            element: <ProtectedRouteMIS element={<UpiPayOuts />} />
        },
        {
            path: 'merchantsthroughcooperative',
            element: <ProtectedRouteMIS element={<MerchantThroughCoOperative />} />
        },
        {
            path: 'bcpoints',
            element: <ProtectedRouteMIS element={<BcPoints />} />
        }
    ]
};

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/merchant-home',
            element: <ProtectedRoute element={<DashboardDefault />} />
        },
        {
            path: 'unauthorized-section',
            element: <ProtectedRoute element={<UnauthorizedSection />} />
        },
        {
            path: 'pending-section',
            element: <ProtectedRoute element={<IciciPending />} />
        },
        {
            path: 'public-message',
            element: <ProtectedRoute element={<PublicMessage />} />
        },
        // {
        //     path: 'dashboard',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <ProtectedRoute element={<DashboardDefault />} />
        //         }
        //     ]
        // },
        {
            path: 'dmt',
            children: [
                {
                    path: 'dmtTable',
                    element: <ProtectedRoute element={<ConditionalRenderDmtTable />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                },
                {
                    path: 'iserveuDmtTable',
                    element: <ProtectedRoute element={<DmtTableIserveu />} />
                },
                {
                    path: 'fundTransfer',
                    element: <ProtectedRoute element={<ConditionalRenderTransferPage />} />
                },
                {
                    path: 'dmtTransfer',
                    element: <ProtectedRoute element={<ConditionalRenderDmtTransfer />} />
                },
                {
                    path: 'checkBeneficiary',
                    element: <ProtectedRoute element={<ConditionalRenderCheckBenef />} />
                },
                {
                    path: 'ListBeneficiary',
                    element: <ProtectedRoute element={<ListAllBeneficiary />} />
                },

                {
                    path: 'ListMoneyTransfer',
                    element: <ProtectedRoute element={<ListMoneyTransfer />} />
                },
                {
                    path: 'CreateBeneficiary',
                    element: <ProtectedRoute element={<CreateBeneficiary />} />
                },
                {
                    path: 'Createcustomer',
                    element: <ProtectedRoute element={<ConditionalRenderCreateCustomer />} />
                },
                {
                    path: 'transferReceipt',
                    element: <ProtectedRoute element={<ReceiptPage />} />
                },
                {
                    path: 'dmtreport',
                    element: <ProtectedRoute element={<DmtReport />} />
                },
                {
                    path: 'dmtreciept',
                    element: <ProtectedRoute element={<ReportReciept />} />
                }
            ]
        },
        {
            path: 'mobile-banking',
            children: [
                {
                    path: 'apiregistration',
                    element: <ProtectedRoute element={<ApiRegistration />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                }
            ]
        },
        {
            path: 'atmupi',
            element: <ProtectedRoute element={<AtmUpi />} />
        },
        {
            path: 'atmupi-history',
            element: <ProtectedRoute element={<AtmUpiHistory />} />
        },
        {
            path: 'atmupi-message',
            element: <ProtectedRoute element={<AtmUpiMessage />} />
        },
        {
            path: 'aeps',
            children: [
                {
                    path: 'aadhaarpay',
                    element: <ProtectedRoute element={<AadhaarPay />} />
                },
                {
                    path: 'balanceEnquiry',
                    element: <ProtectedRoute element={<BalanceEnquiry />} />
                },
                {
                    path: 'cashWithdrawal',
                    element: <ProtectedRoute element={<CashWithdrawal />} />
                },
                {
                    path: 'miniStatementAadhaar',
                    element: <ProtectedRoute element={<MiniStatementAadhaar />} />
                },
                {
                    path: 'miniStatement',
                    element: <ProtectedRoute element={<MiniStatementReciept />} />
                },
                {
                    path: 'BalanceEnquiryReciept',
                    element: <ProtectedRoute element={<BalanceEnquiryReciept />} />
                },
                {
                    path: 'iciciregistration',
                    element: <ProtectedRoute element={<Merchantmodel />} />
                },
                {
                    path: 'Address',
                    element: <ProtectedRoute element={<Address />} />
                },
                {
                    path: 'kyc',
                    element: <ProtectedRoute element={<KYC />} />
                },
                {
                    path: 'aepsreport',
                    element: <ProtectedRoute element={<AepsReport />} />
                },
                {
                    path: 'aadhaarpayreport',
                    element: <ProtectedRoute element={<AadhaarpayReport />} />
                },
                {
                    path: 'aepsauthentication',
                    element: <ProtectedRoute element={<Aeps_Authentication />} />
                },
                {
                    path: 'kycauthentication',
                    element: <ProtectedRoute element={<Kycauthentication />} />
                }
            ]
        },
        {
            path: 'bbps',
            children: [
                {
                    path: 'billPayments',
                    element: <ProtectedRoute element={<BillPayments />} />
                },
                {
                    path: 'bbpsform',
                    element: <ProtectedRoute element={<BbpsForm />} />
                },
                {
                    path: 'bbpspending',
                    element: <ProtectedRoute element={<Bbpspending />} />
                },
                {
                    path: 'bbpsauth',
                    element: <ProtectedRoute element={<BbpsAuth />} />
                },
                {
                    path: 'bbpsonbaord',
                    element: <ProtectedRoute element={<BbpsOnBoardWidget />} />
                },
                {
                    path: 'bbpsonboardpending',
                    element: <ProtectedRoute element={<BbpsOnboardPending />} />
                },
                {
                    path: 'bill-receipt',
                    element: <ProtectedRoute element={<BillReceipt />} />
                },
                {
                    path: 'bill-print',
                    element: <ProtectedRoute element={<BillPrint />} />
                },
                {
                    path: 'bill-list',
                    element: <ProtectedRoute element={<BillList />} />
                },
                {
                    path: 'bill-invoice',
                    element: <ProtectedRoute element={<BillInvoices />} />
                }
            ]
        },
        {
            path: 'common-service',
            children: [
                {
                    path: 'government-services',
                    element: <ProtectedRoute element={<CommonService />} />
                },
                {
                    path: 'sub-services',
                    element: <ProtectedRoute element={<SubServices />} />
                },
                {
                    path: 'insurance',
                    element: <ProtectedRoute element={<InsuranceRedirect />} />
                },
                {
                    path: 'helpingtools',
                    element: <ProtectedRoute element={<HelpingTools />} />
                },
                {
                    path: 'insurance-report',
                    element: <ProtectedRoute element={<InsuranceReport />} />
                },
                {
                    path: 'insurance-comingsoon',
                    element: <ProtectedRoute element={<InsuranceComingsoon />} />
                }
            ]
        },
        {
            path: 'pancard',
            children: [
                {
                    path: 'pancard-register',
                    element: <ProtectedRoute element={<RegisterPancard />} />
                },
                {
                    path: 'buy-coupon',
                    element: <ProtectedRoute element={<BuyCouponPage />} />
                },
                {
                    path: 'view-coupon',
                    element: <ProtectedRoute element={<ViewCouponPage />} />
                },
                {
                    path: 'view-registration',
                    element: <ProtectedRoute element={<ViewRegistrationPage />} />
                },
                {
                    path: 'edit-resubmit',
                    element: <ProtectedRoute element={<EditResubmitPage />} />
                }
            ]
        },
        {
            path: 'bookings',
            children: [
                {
                    path: 'train-ticket',
                    element: <ProtectedRoute element={<TrainTicket />} />
                }
            ]
        },
        {
            path: 'customer-support',
            children: [
                {
                    path: 'ticket-management',
                    element: <ProtectedRoute element={<TicketManagement />} />
                },
                {
                    path: 'question-answer',
                    element: <ProtectedRoute element={<SupportChat />} />
                }
            ]
        },
        {
            path: 'bbps',
            children: [
                {
                    path: 'bbpsRegistration',
                    element: <ProtectedRoute element={<BbpsRegistration />} />
                },
                {
                    path: 'bbpsreport',
                    element: <ProtectedRoute element={<BillPaymentsReport />} />
                },
                {
                    path: 'bbpsreceipt',
                    element: <ProtectedRoute element={<BbpsReceipt />} />
                },
                {
                    path: 'rechargepage',
                    element: <ProtectedRoute element={<Recharges />} />
                },
                {
                    path: 'rechargereport',
                    element: <ProtectedRoute element={<RechargeReport />} />
                },
                {
                    path: 'rechargereceipt',
                    element: <ProtectedRoute element={<RechargeReceipt />} />
                },
                {
                    path: 'dthpage',
                    element: <ProtectedRoute element={<DthPage />} />
                }
            ]
        },
        {
            path: 'matm',
            children: [
                {
                    path: 'iserveu-matm-registration',
                    element: <ProtectedRoute element={<IserveuMATMRegistration />} />
                },
                {
                    path: 'matm-report',
                    element: <ProtectedRoute element={<MatmReport />} />
                }
            ]
        },
        {
            path: 'distributor',
            children: [DistributorRoutes]
        },
        {
            path: 'super-distributor',
            children: [SuperdistributorRoutes]
        },
        {
            path: 'mis',
            children: [MisRoutes]
        },
        {
            path: 'employee',
            children: [EmployeeRoutes]
        },
        {
            path: 'merchants',
            children: [
                {
                    path: 'transactions',
                    element: <ProtectedRoute element={<Transactions />} />
                },
                {
                    path: 'Wallet3',
                    element: <ProtectedRoute element={<Wallet3 />} />
                },

                {
                    path: 'wallet',
                    element: <ProtectedRoute element={<LoadWallet />} />
                },
                {
                    path: 'upi',
                    element: <ProtectedRoute element={<UpiWalletRequestMerchant />} />
                },
                {
                    path: 'requestupi',
                    element: <ProtectedRoute element={<WalletRequestQrUpi />} />
                },
                {
                    path: 'profile',
                    element: <ProtectedRoute element={<ProfilePage />} />
                },
                {
                    path: 'edit-profile',
                    element: <ProtectedRoute element={<EditProfile />} />
                },
                {
                    path: 'primary-profile-update',
                    element: <ProtectedRoute element={<PrimaryProfileUpdate />} />
                },
                {
                    path: 'mandatory-password-reset',
                    element: <ProtectedRoute element={<MandatoryPasswordReset />} />
                },
                {
                    path: 'all-transactions',
                    element: <ProtectedRoute element={<AllTransactions />} />
                },
                {
                    path: 'settlement-request',
                    element: <ProtectedRoute element={<SettlementRequest />} />
                },
                {
                    path: 'create-merchant-employee',
                    element: <ProtectedRoute element={<CreateMerchantEmployee />} />
                }
            ]
        },
        {
            path: 'pos',
            children: [
                {
                    path: 'pos-report',
                    element: <ProtectedRoute element={<PosReport />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                }
            ]
        },
        {
            path: 'qr',
            children: [
                {
                    path: 'myqr',
                    element: <ProtectedRoute element={<Qr />} />
                    // element: <ProtectedRoute element={<DmtTableIserveu />} />
                }
            ]
        },
        {
            path: 'rd',
            children: [
                {
                    path: 'rdservices',
                    element: <ProtectedRoute element={<RDServices />} />
                },
                {
                    path: 'rdservices2',
                    element: <ProtectedRoute element={<RDServices2 />} />
                },
                {
                    path: 'rdservices3',
                    element: <ProtectedRoute element={<RDServices3 />} />
                },
                {
                    path: 'rdservices4',
                    element: <ProtectedRoute element={<RDServices4 />} />
                }
            ]
        },
        {
            path: 'premium-bc',
            children: [
                {
                    path: 'premium-bc-class-cards',
                    element: <ProtectedRoute element={<PremumBcClassCard />} />
                }
            ]
        },
        {
            path: '/',
            children: [
                {
                    path: 'helpline',
                    element: <ProtectedRoute element={<HelpLine />} />
                },
                {
                    path: 'iframe-test',
                    element: <ProtectedRoute element={<TestIframe />} />
                },
                {
                    path: 'upgrade-premium-bc',
                    element: <ProtectedRoute element={<UpgradePremiumBc />} />
                },
                {
                    path: 'power-bi-report',
                    element: <ProtectedRoute element={<PowerBiReport />} />
                }
            ]
        }
    ]
};

export default MainRoutes;
