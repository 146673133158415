/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const employee = {
    id: 'newUtilities',
    // title: 'distributor',
    type: 'group',
    children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'employee/dashboard',
            breadcrumbs: true
        },
        {
            id: 'tabler-icons2',
            title: 'Profile',
            type: 'item',
            url: 'employee/profile',
            breadcrumbs: true
        },
        {
            id: 'icons2211',
            title: 'Merchants',
            type: 'collapse',
            children: [
                {
                    id: 'sample-page211',
                    title: 'Create Merchants',
                    type: 'item',
                    url: '/employee/Create-Merchant',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons521',
                    title: 'List Merchants',
                    type: 'item',
                    url: '/employee/list-merchant',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default employee;
