import { Navigate } from 'react-router-dom';

const withAccessControlResetPassword = (WrappedComponent) => {
    return (props) => {
        const isPasswordValid = localStorage.getItem('isPasswordValid');

        if (isPasswordValid !== 'true') {
            console.log('password hoc true');
            return <Navigate to="/merchants/mandatory-password-reset" />;
        } else {
            return <WrappedComponent {...props} />;
        }
    };
};

export default withAccessControlResetPassword;

// In your service components
// import withAccessControl from './withAccessControl';

// const Service1 = () => <div>Service 1 Content</div>;
// const Service2 = () => <div>Service 2 Content</div>;

// export const Service1WithAccessControl = withAccessControl(Service1, 2);
// export const Service2WithAccessControl = withAccessControl(Service2, 3);
